import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { encrypt, GetPushState } from './Utils';
import { Admin, UserRole } from './Models';
import Header from './Components/Partials/Header';
import LoginFlow from './Pages/LoginFlow';
import AdminNav from './Components/Partials/AdminNav';
import UpdateDB from './Pages/UpdateDB';
import Referrals from './Pages/Referrals';
import ProtectedRoute from './Components/Partials/ProtectedRoute';
import AuthKeys from './Pages/AuthKeys';

const App: React.FC = () => {
  const setUserAfterLogin = (user: Admin) => {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    document.cookie = `email=${encrypt(user.email)}; expires=${date}; path="/"`;
    document.cookie = `full_name=${encrypt(user.full_name)}; expires=${date}; path="/"`;
    document.cookie = `home_region=${encrypt(user.home_region)}; expires=${date}; path="/"`;
    document.cookie = `role=${encrypt(user.role)}; expires=${date}; path="/"`;

    window.history.pushState({ page_id: 1 }, '', GetPushState(true)); //!Pushstate isn't super advanced, but utlity function returns values depending on env...
    window.location.reload();
  };

  //Remove cookies twice and have awkward settimeout because the removecookie sometimes fails the first time -- and takes time
  const removeUserAfterLogout = () => {
    const date = new Date();
    date.setDate(date.getDate() - 3);

    document.cookie = `email=; expires=${date}; path="/"`;
    document.cookie = `full_name=; expires=${date}; path="/"`;
    document.cookie = `home_region=; expires=${date}; path="/"`;
    document.cookie = `role=; expires=${date}; path="/"`;
    setTimeout(() => {
      window.history.pushState({ page_id: 1 }, '', GetPushState(false)); //!Pushstate isn't super advanced, but utlity function returns values depending on env...
      window.location.reload();
    }, 500);
  };

  return (
    <div className="App">
      <Header LogoutUser={removeUserAfterLogout} />
      <Switch>
        <Route
          path="/"
          exact
          render={(props) => {
            return <LoginFlow SetUserAfterLogin={setUserAfterLogin} routerProps={props} />;
          }}
        />
        <ProtectedRoute path="/manage" role={UserRole.Admin} component={AdminNav} />
      </Switch>
      <Switch>
        <ProtectedRoute path="/manage/mongo" role={UserRole.Admin} component={UpdateDB} />
        <ProtectedRoute path="/manage/referrals" role={UserRole.Admin} component={Referrals} />
        <ProtectedRoute path="/manage/authkeys" role={UserRole.Admin} component={AuthKeys} />
      </Switch>
    </div>
  );
};

export default App;
