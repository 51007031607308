import React, { useState } from 'react';
import { Databases, TargetUsers, Countries } from '../Models';
import { GetBaseURL } from '../Utils';
import ErrorHandler from './Partials/ErrorHandler';

export type UpdateUsersProps = {
  db: Databases;
  users: TargetUsers;
  country: Countries;
};

const UpdateUsers: React.FC<UpdateUsersProps> = ({ db, users, country }) => {
  const [waitingForServer, setWaitingForServer] = useState(false);
  const [choicesConfirmed, setChoicesConfirmed] = useState(false);
  const [updateRequestSuccess, setUpdateRequestSuccess] = useState(false);
  const [updateRequestSuccessMessage, setUpdateRequestSuccessMessage] = useState<string | null>(null);
  const [updateRequestError, setUpdateRequestError] = useState(false);
  const [updateRequestErrorMessage, setUpdateRequestErrorMessage] = useState<string | null>(null);

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    const URL = `${GetBaseURL()}/api/${users.toLowerCase()}/update/${country}/${db}?key=${
      process.env.REACT_APP_API_KEY
    }`;

    const REQUEST = await fetch(URL);
    if (REQUEST.status === 200) {
      const response = await REQUEST.json();
      setUpdateRequestSuccessMessage(response?.message || `${users} successfully updated in ${country} ${db} database`);
      setUpdateRequestSuccess(true);
      setWaitingForServer(false);
      setChoicesConfirmed(false);
    } else {
      const response = await REQUEST.json();
      setUpdateRequestErrorMessage(response?.message || 'There was an internal server error');
      setUpdateRequestError(true);
      setWaitingForServer(false);
      setChoicesConfirmed(false);
    }
  };

  return (
    <section className="update__container">
      <h2 className="update__container--title">
        Update {country} {users} in {db} Database
      </h2>

      {updateRequestSuccess && updateRequestSuccessMessage !== null ? (
        <ErrorHandler
          type="success"
          handler={() => {
            setUpdateRequestSuccess(false);
          }}
          message={updateRequestSuccessMessage}
        />
      ) : null}

      {updateRequestError && updateRequestErrorMessage !== null ? (
        <ErrorHandler
          type="error"
          handler={() => {
            setUpdateRequestError(false);
          }}
          message={updateRequestErrorMessage}
        />
      ) : null}

      <div className="update__container--button-container">
        {waitingForServer ? (
          <p>Your request has been sent... Waiting for a response</p>
        ) : choicesConfirmed ? (
          <div>
            <p>
              Are you sure you want to update {country} {users} to the {db} Database?
            </p>
            <button
              onClick={(event: React.FormEvent) => {
                setWaitingForServer(true);
                submitHandler(event);
              }}
            >
              Yes -- submit file
            </button>
            <button
              onClick={() => {
                setChoicesConfirmed(false);
              }}
            >
              No!
            </button>
          </div>
        ) : (
          <button
            className="update__container--button"
            onClick={() => {
              setChoicesConfirmed(true);
            }}
          >
            Update {users}
          </button>
        )}{' '}
      </div>
    </section>
  );
};

export default UpdateUsers;
