import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { FindCookieValue } from '../../Utils';
import { CookieTypes } from '../../Models';

type ProtectedRouteProps = {
  component: React.FC<RouteComponentProps>;
  path: string;
  role: string;
  exact?: boolean;
  cardProvider?: boolean;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
  path,
  role,
  cardProvider = false,
  exact = false,
}) => {
  if (FindCookieValue(CookieTypes.Role) === role && exact === true) {
    return (
      <>
        <Route to={path} exact component={component} />
      </>
    );
  } else if (FindCookieValue(CookieTypes.Role) === role && exact === false) {
    return (
      <>
        <Route to={path} exact component={component} />
      </>
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default ProtectedRoute;
