export enum Databases {
  test = 'Testing',
  dev = 'Developer',
  production = 'Production',
}

export enum TargetUsers {
  client = 'Clients',
  tutor = 'Tutors',
  lesson = 'Lessons',
}

export enum Countries {
  Canada = 'Canada',
  USA = 'USA',
}
