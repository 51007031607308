//Dependencies
import React from 'react';
import { GetBaseURL } from '../../Utils';

type LogoutProps = {
  name?: string;
  LogoutUser: () => void;
};

const Logout: React.FC<LogoutProps> = ({ name, LogoutUser }) => {
  const logoutHandler = async () => {
    const LOGOUT_REQUEST = await fetch(`${GetBaseURL()}/api/loginlogout/logout?key=${process.env.REACT_APP_API_KEY}`);
    const RES = LOGOUT_REQUEST.status;

    if (RES === 200) {
      LogoutUser();
    }
  };

  return (
    <div className="logout pointer" onClick={logoutHandler}>
      <h4>{name}</h4>
    </div>
  );
};

export default Logout;
