import React, { useState } from 'react';
import ReferralUpdates from '../Components/ReferralUpdates';

type ReferralTypes = 'Initial' | 'StatusUpdate' | 'Rewards' | 'SMS';

const Referrals: React.FC = () => {
  const [referralType, setReferralType] = useState<ReferralTypes>('Initial');

  return (
    <main className="main-container">
      <h2 className="page__title">Referrals:</h2>

      <div className="referral__nav">
        <span
          onClick={() => {
            setReferralType('Initial');
          }}
        >
          Initial Emails
        </span>
        <span
          onClick={() => {
            setReferralType('SMS');
          }}
        >
          SMS Referrals
        </span>
        <span
          onClick={() => {
            setReferralType('StatusUpdate');
          }}
        >
          Referral Status Updates
        </span>
        <span
          onClick={() => {
            setReferralType('Rewards');
          }}
        >
          Referral Rewards
        </span>
      </div>

      {referralType === 'Initial' ? (
        <ReferralUpdates
          title="Send Initial Referrals"
          fetchRoute="fetchNewClients"
          buttonText="Send Emails"
          postRoute="sendNew"
        />
      ) : null}
      {referralType === 'SMS' ? (
        <ReferralUpdates
          title="Send SMS Referrals"
          fetchRoute="fetchSMSReferrals"
          buttonText="Send Messages"
          postRoute="sendSMSLinks"
        />
      ) : null}
      {referralType === 'StatusUpdate' ? (
        <ReferralUpdates
          title="Referral Status Updates"
          fetchRoute="fetchNewReferred"
          buttonText="Send Emails"
          postRoute="statusUpdates"
        />
      ) : null}
      {referralType === 'Rewards' ? (
        <ReferralUpdates
          title="Referral Rewards"
          fetchRoute="fetchClientForRewards"
          buttonText="Grant Rewards!"
          postRoute="rewards"
        />
      ) : null}
    </main>
  );
};

export default Referrals;
