import React from 'react';

interface ErrorProps {
  error?: string | null;
  message?: string;
  type: 'error' | 'warning' | 'success' | 'info';
  handler?: () => void;
  icon?: string;
}

const ErrorHandler: React.FC<ErrorProps> = ({ error, message, type, handler, icon }) => {
  const classVar = 'default-margin-top-bottom isa_' + type;
  return (
    <div className={classVar} onClick={handler} data-cy="error_handler">
      <i className={icon ? icon : 'fa fa-times-circle'}></i>
      {error ? error : message}
    </div>
  );
};

export default ErrorHandler;
