//Dependencies
import React, { useState, useEffect, useRef, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SHA256, enc } from 'crypto-js';
//Types
import { Admin, CookieTypes, UserRole } from '../Models';
import { FindCookieValue, GetBaseURL } from '../Utils';
import ErrorHandler from '../Components/Partials/ErrorHandler';

type LoginFlowProps = {
  SetUserAfterLogin: (user: Admin) => void;
  routerProps: RouteComponentProps;
};

const LoginFLow: React.FC<LoginFlowProps> = ({ SetUserAfterLogin, ...routerProps }) => {
  const [error, setError] = useState<string | null>(null);
  const EmailInput = useRef<HTMLInputElement>(null);
  const PasswordInput = useRef<HTMLInputElement>(null);
  const LoginForm = useRef<HTMLFormElement>(null);

  useEffect(() => {
    //if we have an encryopted cookie under "role", which can be decripted to match Admin, we set logged in to be true
    //So that we can redirect to the Admin Page.
    if (
      FindCookieValue(CookieTypes.FullName) &&
      FindCookieValue(CookieTypes.HomeRegion) &&
      FindCookieValue(CookieTypes.Email) &&
      FindCookieValue(CookieTypes.Role) &&
      FindCookieValue(CookieTypes.Role) === UserRole.Admin
    ) {
      let user: Admin = {
        email: FindCookieValue(CookieTypes.Email)! as string,
        role: FindCookieValue(CookieTypes.Role)! as string,
        full_name: FindCookieValue(CookieTypes.FullName)! as string,
        home_region: FindCookieValue(CookieTypes.HomeRegion)! as string,
      };
      SetUserAfterLogin(user);
    }
  });

  const resetError = () => {
    setError(null);
  };

  //!Handling
  const loginHandler = async (event: FormEvent) => {
    event.preventDefault();

    const Email = EmailInput.current!.value;
    const Password = PasswordInput.current!.value;
    const HashedWithSHA256 = SHA256(Password); //All passwords are stored using this hash --> should make a util function.
    const HashedPassword = HashedWithSHA256.toString(enc.Base64);

    const User = {
      email: Email,
      password: HashedPassword,
    };

    //!Route to login
    const Request = await fetch(`${GetBaseURL()}/api/loginlogout/admin?key=${process.env.REACT_APP_API_KEY}`, {
      method: 'POST',
      body: JSON.stringify(User),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const Res = await Request.json();
    const ResponseStatus = Request.status;

    if (ResponseStatus === 200) {
      SetUserAfterLogin(Res);
    } else {
      setError(Res.message);
    }
  };

  return (
    <form className="login-form" onSubmit={loginHandler} ref={LoginForm}>
      {error ? <ErrorHandler error={error} type="error" handler={resetError} /> : null}
      <div className="login-input">
        <label>Email</label>
        <input type="text" ref={EmailInput} placeholder="Email" />
      </div>
      <div className="login-input">
        <label>Password</label>
        <input type="password" ref={PasswordInput} placeholder="Password" />
      </div>
      <button className="login-button">Login</button>
    </form>
  );
};

export default LoginFLow;
