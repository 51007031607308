import React, { useState, useRef } from 'react';
import { Databases, TargetUsers, Countries } from '../../Models';
import { GetBaseURL } from '../../Utils';
import ErrorHandler from '../Partials/ErrorHandler';

export type UploadCSVProps = {
  db: Databases;
  users: TargetUsers;
  country: Countries;
};

const UploadCSV: React.FC<UploadCSVProps> = ({ db, users, country }) => {
  const [waitingForServer, setWaitingForServer] = useState(false);
  const [choicesConfirmed, setChoicesConfirmed] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState<null | string>(null);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [fileUploadSuccessMessage, setFileUploadSuccessMessage] = useState<null | string>(null);
  const fileNameRef = useRef<HTMLInputElement>(null);
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    const PDF_FILE = fileUploadRef.current;
    if (PDF_FILE === null || PDF_FILE.files === null || PDF_FILE.files.length === 0) {
      console.log('No file submitted');
    } else {
      const NAME_OF_FILE = fileNameRef.current?.value || 'untitled';
      const FD = new FormData();
      FD.append(NAME_OF_FILE, PDF_FILE.files[0]);

      const URL = `${GetBaseURL()}/api/${users.toLowerCase()}/add/${country}/${db}?key=${
        process.env.REACT_APP_API_KEY
      }`;

      const POST_REQUEST = await fetch(URL, {
        method: 'POST',
        body: FD,
      });

      if (POST_REQUEST.status === 200) {
        const response = await POST_REQUEST.json();
        setFileUploadSuccessMessage(response?.message || `${users} successfully updated in ${country} ${db} database`);
        setFileUploadSuccess(true);
        setWaitingForServer(false);
        setChoicesConfirmed(false);
      } else {
        const response = await POST_REQUEST.json();
        setFileUploadErrorMessage(response?.message || 'There was an internal server error');
        setFileUploadError(true);
        setWaitingForServer(false);
        setChoicesConfirmed(false);
      }
    }
  };

  return (
    <section className="form__container">
      <h3 className="form__container--heading">
        Add {country} {users} to {db} Database:
      </h3>
      {fileUploadSuccess && fileUploadSuccessMessage !== null ? (
        <ErrorHandler
          type="success"
          handler={() => {
            setFileUploadSuccess(false);
          }}
          message={fileUploadSuccessMessage}
        />
      ) : null}

      {fileUploadError && fileUploadErrorMessage !== null ? (
        <ErrorHandler
          type="error"
          handler={() => {
            setFileUploadError(false);
          }}
          message={fileUploadErrorMessage}
        />
      ) : null}
      <form className="file__submit">
        <div className="file__submit--form">
          <label>File: </label>
          <input ref={fileUploadRef} type="file" name="csv" id="csvFile" />
        </div>
        {waitingForServer ? (
          <p>Your request has been sent... Waiting for a response</p>
        ) : choicesConfirmed ? (
          <div>
            <p>
              Are you sure you want to Add {country} {users} to the {db} Database?
            </p>
            <button
              onClick={(event: React.FormEvent) => {
                setWaitingForServer(true);
                submitHandler(event);
              }}
            >
              Yes -- submit file
            </button>
            <button
              onClick={() => {
                setChoicesConfirmed(false);
              }}
            >
              No!
            </button>
          </div>
        ) : (
          <button
            className="file__submit--button"
            onClick={() => {
              setChoicesConfirmed(true);
            }}
          >
            Submit CSV
          </button>
        )}
      </form>
    </section>
  );
};

export default UploadCSV;
