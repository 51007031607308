import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../Assets/home.png';

const AdminNav: React.FC = () => {
  return (
    <nav>
      <div className="logo">
        <img src={Logo} alt="No Fuss Tutors logo"></img>
      </div>
      <Link className="link" to="/manage/referrals">
        <i className="fas fa-circle">Referrals</i>
      </Link>
      <Link className="link" to="/manage/mongo">
        <i className="fas fa-circle">DB Management</i>
      </Link>
      <Link className="link" to="/manage/authkeys">
        <i className="fas fa-circle">Auth Keys</i>
      </Link>
    </nav>
  );
};

export default AdminNav;
