import React, { useState, useEffect } from 'react';
import { GetBaseURL } from '../Utils';

type ReferralProps = {
  title: string;
  fetchRoute: string;
  postRoute: string;
  buttonText: string;
  //   ClientHandler: (emailType: string, ) => boolean;
};

type ClientData = {
  mongoID: string;
  user_data: { full_name: string; email: string; country: string; tutorcruncher_id?: number };
};

const ReferralUpdates: React.FC<ReferralProps> = ({ title, fetchRoute, buttonText, postRoute }) => {
  const [clients, setClients] = useState<ClientData[]>([]);

  useEffect(() => {
    (async () => {
      const REQUEST = await fetch(
        `${GetBaseURL()}/api/clients/referrals/${fetchRoute}?key=${process.env.REACT_APP_API_KEY}`,
      );

      if (REQUEST.status === 200) {
        const USER_DATA: { _id: string; user_data: any }[] = await REQUEST.json();
        const CLIENT_DATA: ClientData[] = USER_DATA.map((user) => {
          return {
            mongoID: user._id,
            user_data: {
              full_name: user.user_data.full_name,
              email: user.user_data.email,
              country: user.user_data.country,
              tutorcruncher_id: user.user_data.tutorcruncher_id ? user.user_data.tutorcruncher_id : undefined,
            },
          };
        });

        setClients(CLIENT_DATA);
      }
    })();
  }, []);

  const SendClientEmails = async () => {
    const clientIDs = clients.map((singleClient) => {
      return singleClient.mongoID;
    });

    const URL = `${GetBaseURL()}/api/clients/referrals/${postRoute}?key=${process.env.REACT_APP_API_KEY}`;
    const REQUEST = await fetch(URL, {
      method: 'POST',
      body: JSON.stringify({ clientIDs: clientIDs }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (REQUEST.status === 200) {
      console.log('Successfully completed update');
      return true;
    } else return false;
  };

  return (
    <div className="referral__list">
      <h3 className="referral__list--title">{title}</h3>

      <section>
        <div className="referral__list--heading">
          <h4>Client List: {clients.length}</h4> <button onClick={SendClientEmails}>{buttonText}</button>
        </div>
        {clients.map((singleClient) => {
          return (
            <div key={singleClient.mongoID} className="referral__list--item">
              <span>Client Name: {singleClient.user_data.full_name}</span>
              <span>Client Email: {singleClient.user_data.email}</span>
              <span>Client Country: {singleClient.user_data.country}</span>
              <span>
                Tutorcruncher ID:{' '}
                {singleClient.user_data.tutorcruncher_id
                  ? singleClient.user_data.tutorcruncher_id
                  : 'Not in tutorcruncher yet'}
              </span>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default ReferralUpdates;
