import { decrypt } from './index';

export const FindCookieValue = (cookieProperty: string) => {
  const COOKIE_ARRAY = document.cookie.split('; ');
  const CHECKED = COOKIE_ARRAY.some((cookie: string) => {
    return cookie.includes(cookieProperty);
  });
  if (CHECKED) {
    let returnValue = '';
    COOKIE_ARRAY.forEach((cookie: string) => {
      if (cookie.includes(cookieProperty)) {
        const ENCRYPTED_COOKIE = cookie.split('=')[1];
        returnValue = decrypt(ENCRYPTED_COOKIE);
      }
    });
    return returnValue;
  } else {
    return false;
  }
};
