import React, { useState, useRef } from 'react';
import ErrorHandler from '../Components/Partials/ErrorHandler';
import { GetBaseURL } from '../Utils';

const AuthKeys: React.FC = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasRequestError, setHasRequestError] = useState(false);
  const [hasRequestSuccess, setHasRequestSuccess] = useState(false);
  const [requestMessage, setRequestMessage] = useState('');
  const newUserRef = useRef<HTMLInputElement>(null);

  const SubmitHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsDisabled(true);
    const USER = newUserRef.current?.value;

    if (USER) {
      const URL = `${GetBaseURL()}/api/authKey/new?key=${process.env.REACT_APP_API_KEY}`;
      const REQUEST = await fetch(URL, {
        method: 'POST',
        body: JSON.stringify({ user: USER }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (REQUEST.status === 400) {
        setHasRequestError(true);
        const ERRROR_MESSAGE = await REQUEST.json();
        setRequestMessage(ERRROR_MESSAGE.message);
      } else if (REQUEST.status === 200) {
        setHasRequestSuccess(true);
        const ERRROR_MESSAGE = await REQUEST.json();
        setRequestMessage(ERRROR_MESSAGE.message);
      }
    } else {
      setHasRequestError(true);
      setRequestMessage('The User entered was not valid. Please re-enter a user to submit the form again.');
    }

    setIsDisabled(false);
  };

  return (
    <main className="main-container">
      <h2 className="page__title">Referrals:</h2>
      <form onSubmit={SubmitHandler}>
        {hasRequestError || hasRequestSuccess ? (
          <ErrorHandler message={requestMessage} type={hasRequestError ? 'error' : 'success'} />
        ) : null}
        <div>
          <span>Auth Key User: </span>
          <input ref={newUserRef} type="text" placeholder="New User..." />
        </div>
        <button disabled={isDisabled}>Submit!</button>
      </form>
    </main>
  );
};

export default AuthKeys;
