//Dependencies
import React from 'react';
//Components
import Logout from './Logout';
//Types
import { UserRole, CookieTypes } from '../../Models';
//Utils
import { FindCookieValue, printInitials } from '../../Utils';

import logo from '../../Assets/home.png';

type HeaderProps = {
  LogoutUser: () => void;
};

const Header: React.FC<HeaderProps> = ({ LogoutUser }) => {
  //When this condition isn't here decrypt will throw an error because cookies.role is undefined.

  if (document.cookie && FindCookieValue(CookieTypes.Role)) {
    const UserInitials = FindCookieValue('full_name');
    return (
      <header className="login-header">
        {FindCookieValue(CookieTypes.Role) === UserRole.Admin ||
        FindCookieValue(CookieTypes.Role) === UserRole.Client ||
        FindCookieValue(CookieTypes.Role) === UserRole.Student ||
        FindCookieValue(CookieTypes.Role) === UserRole.Tutor ? (
          <div className="flex align-right">
            <div className="avatar header-avatar">{printInitials(UserInitials as string)}</div>
            <Logout name={FindCookieValue('full_name') as string} LogoutUser={LogoutUser} />
          </div>
        ) : null}
      </header>
    );
  } else {
    return (
      <header className="login-header">
        <nav>
          <div className="logo">
            <img src={logo}></img>
          </div>
        </nav>
      </header>
    );
  }
};

export default Header;
