import React, { useState } from 'react';
import UpdateUsers from '../Components/UpdateUsers';
import UploadCSV from '../Components/UploadFiles/UploadCSV';
import { Databases, TargetUsers, Countries } from '../Models';

const UpdateDB: React.FC = () => {
  const [currentDB, setCurrentDB] = useState<Databases>(Databases.dev);
  const [currentUsers, setCurrentUsers] = useState<TargetUsers>(TargetUsers.client);
  const [currentCountry, setCurrentCountry] = useState<Countries>(Countries.Canada);

  const selectDatabase = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (e.target.value !== '') {
      switch (e.target.value) {
        case 'dev':
          setCurrentDB(Databases.dev);
          break;
        case 'production':
          setCurrentDB(Databases.production);
          break;
        case 'test':
          setCurrentDB(Databases.test);
          break;
      }
    }
  };

  const selectTargetUsers = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (e.target.value !== '') {
      switch (e.target.value) {
        case 'tutor':
          setCurrentUsers(TargetUsers.tutor);
          break;
        case 'client':
          setCurrentUsers(TargetUsers.client);
          break;
        case 'lesson':
          setCurrentUsers(TargetUsers.lesson);
          break;
      }
    }
  };

  const selectCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (e.target.value !== '') {
      switch (e.target.value) {
        case 'USA':
          setCurrentCountry(Countries.USA);
          break;
        case 'Canada':
          setCurrentCountry(Countries.Canada);
          break;
      }
    }
  };

  return (
    <main className="main-container">
      <h2 className="page__title">
        Updates for {currentCountry} {currentUsers} in {currentDB} Database
      </h2>

      <section className="database__options">
        <div className="database__select">
          <label>Select Country: </label>
          <select onBlur={selectCountry}>
            <option disabled selected>
              Select One
            </option>
            <option value={'USA'}>{Countries.USA}</option>
            <option value={'Canada'}>{Countries.Canada}</option>
          </select>
        </div>

        <div className="database__select">
          <label>Select Target Users: </label>
          <select onBlur={selectTargetUsers}>
            <option disabled selected>
              Select One
            </option>
            <option value={'client'}>{TargetUsers.client}</option>
            <option value={'lesson'}>{TargetUsers.lesson}</option>
            <option value={'tutor'}>{TargetUsers.tutor}</option>
          </select>
        </div>

        <div className="database__select">
          <label>Select DB: </label>
          <select onBlur={selectDatabase}>
            <option disabled selected>
              Select One
            </option>
            <option value={'dev'}>{Databases.dev}</option>
            <option value={'production'}>{Databases.production}</option>
            <option value={'test'}>{Databases.test}</option>
          </select>
        </div>
      </section>

      <UploadCSV db={currentDB} country={currentCountry} users={currentUsers} />

      {currentUsers !== TargetUsers.lesson ? (
        <UpdateUsers db={currentDB} country={currentCountry} users={currentUsers} />
      ) : null}
    </main>
  );
};

export default UpdateDB;
